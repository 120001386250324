<template>
  <div>
    <div
      v-if="loading"
      class="loader">
      <v-progress-circular
        :size="48"
        color="secondary"
        indeterminate/>
    </div>

    <CyFormsRole
      v-else
      ref="formsRole"
      :is-duplicating="isDuplicating"
      :item.sync="role"
      :saving="saving"
      :deleting="deleting"
      :api-errors="roleErrors"
      @on-save="onSave"
      @on-delete="$toggle.show.deleteRoleModal">
      <template slot="alert">
        <CyAlert
          v-if="role.default"
          :title="$t('defaultRoleAlert')"/>
      </template>
    </CyFormsRole>

    <CyModal
      v-if="show.deleteRoleModal"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-text="$t('forms.btnRemove')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.show.deleteRoleModal(false)"
      small
      modal-type="delete">
      <p class="ma-0">
        {{ $t('forms.cannotBeUndone') }}
      </p>
      <p
        class="ma-0"
        v-html="$sanitizeHtml($t('removeRoleStatement', [ role.name ] ))"/>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CyFormsRole from '@/components/forms/role.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageRole',
  components: {
    CyFormsRole,
  },
  breadcrumb () {
    const { $isCreationRoute, role } = this
    const header = $isCreationRoute
      ? this.$t('addRole')
      : role?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.roles'),
        name: 'roles',
      },
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    const { $isCreationRoute, role, loading } = this

    if (this.loading || !role) return {}

    return $isCreationRoute
      ? { title: this.$t('addRole') }
      : {
          title: role?.name,
          createdAt: role?.created_at,
          updatedAt: role?.updated_at,
          loading,
          tag: (role?.default ? this.$t('Default') : null),
        }
  },
  props: {
    roleCanonical: {
      type: String,
      default: '',
    },
    duplicate: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => ({
    show: {
      deleteRoleModal: false,
      detailsBox: false,
      actionsWarning: false,
    },
    deleting: false,
    loading: true,
    saving: false,
  }),
  computed: {
    ...mapState('organization/role', {
      role: (state) => state.detail,
      roleErrors: (state) => state.errors.role,
    }),
    isDuplicating () {
      return !_.$isEmpty(this.duplicate)
    },
  },
  async mounted () {
    const { isDuplicating, roleCanonical } = this

    if (roleCanonical) await this.GET_ROLE({ roleCanonical })
    if (isDuplicating) this.SET_ROLE(this.duplicate)

    this.loading = false
  },
  destroyed () {
    this.RESET_ROLE_STATE()
  },
  methods: {
    ...mapActions('organization/role', [
      'GET_ROLE',
      'CREATE_ROLE',
      'DELETE_ROLE',
      'UPDATE_ROLE',
    ]),
    ...mapMutations('organization/role', [
      'RESET_ROLE_STATE',
      'SET_ROLE',
    ]),
    async onSave ({ rules, name, description }) {
      this.saving = true

      const { $router, $isCreationRoute, roleCanonical, isDuplicating } = this
      const canonical = $isCreationRoute ? this.$getSlug(name) : roleCanonical
      const role = { name, description, rules, canonical }

      this.$isCreationRoute
        ? await this.CREATE_ROLE({ $router, role, isDuplicating })
        : await this.UPDATE_ROLE({ $router, roleCanonical, role })

      if (!_.isEmpty(this.roleErrors)) this.scrollToTop()

      this.saving = false
    },
    async onDelete () {
      const { $router, roleCanonical } = this
      this.deleting = true

      await this.DELETE_ROLE({ $router, roleCanonical })

      if (!_.$isEmpty(this.roleErrors)) this.scrollToTop()

      this.$toggle.show.deleteRoleModal()
      this.deleting = false
    },
    scrollToTop () {
      const cyDetailsEl = _.$get(this.$refs, 'formsRole.$el', {})
      const cyDetailsContentEl = cyDetailsEl.querySelector('.cy-details__content')

      cyDetailsContentEl.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Role detail',
        addRole: 'Create @:role',
        confirmDeleteHeader: 'Remove @:role',
        defaultRoleAlert: 'This is a default @:role and cannot be modified.',
        removeRoleStatement: 'Are you really sure you want to delete the <b>{0}</b> @:role?',
      },
      es: {
        title: 'Detalle del @:Role',
        addRole: 'Crear @:role',
        confirmDeleteHeader: 'Eliminar @:role',
        defaultRoleAlert: 'Este es un @:role predeterminado y no se puede modificar.',
        removeRoleStatement: '¿Está realmente seguro de que desea eliminar el @:role <b>{0}</b>?',
      },
      fr: {
        title: 'Detail du @:Role',
        addRole: 'Créer @:role',
        confirmDeleteHeader: 'Supprimer @:role',
        defaultRoleAlert: `Il s'agit d'un @:role par défaut et ne peut pas être modifié.`,
        removeRoleStatement: 'Êtes-vous vraiment sûr de vouloir supprimer le @:role <b>{0}</b> ?',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  padding: 60px;

  > div {
    display: block;
    margin: 0 auto;
  }
}
</style>
